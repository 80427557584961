export const stepRequiredFields = ['connection', 'template_type', 'name', 'tags']

export const categoriesOptions = [
  {
    key: 'UTILITY',
    name: 'category',
    label: 'Utilidade',
    description: 'Esse tipo de modelo serve para enviar informações importantes como atualizações de contas, pedidos, alertas e muito mais.',
  },
  {
    key: 'MARKETING',
    name: 'category',
    label: 'Marketing',
    description: 'Essa tipo de modelo serve para engajamento, serviços, ofertas de produtos e muito mais.',
  },
]

export const templateTypesOptions = [
  {
    label: 'Mensagem de texto',
    value: 'TEXT',
  },
]

export const headerTypesOptions = [
  {
    label: 'Nenhum',
    value: null,
  },
  {
    label: 'Texto',
    value: 'TEXT',
  },
]

export const languageOptions = [
  {
    label: 'Português',
    value: 'pt_BR',
  },
  {
    label: 'Inglês',
    value: 'en',
  },
  {
    label: 'Espanhol',
    value: 'es',
  },
]

export const initialValues = {
  connection: null,
  category: categoriesOptions[0].key,
  allow_template_category_change: true,
  language: languageOptions[0],
  header: {
    header_type: headerTypesOptions[0],
  },
}
