import styled from 'styled-components'

import { Column, Icon, Row, Typography } from 'design-system'
import { buttonsTemplateDict } from 'configs'
import { formatMessageText } from 'helpers'
import { handleLetterHighlights } from 'utils'

const TemplateCard = ({ data, message, search, messageIdSearch, ...props }) => {
  const handleFormat = (textParams) => {
    if (search && textParams && messageIdSearch) {
      return (
        <Row>
          <Typography>{handleLetterHighlights({ textParams, search }) || textParams}</Typography>
        </Row>
      );
    }
    return (
      <Row>
        <Typography>{textParams}</Typography>
      </Row>
    );
  }

  return (
    <Column width={message ? '100%' : '280px'} {...props}>
      <Column
        p='4px 8px'
        background='transparent'
        borderRadius={data?.buttons?.length > 0 ? '8px 8px 0 0' : '8px'}
      >
        {data?.header && handleFormat(data?.header)}
        {data?.content && (
          <Row mt='16px'>
            <Typography>{(formatMessageText(data?.content || '', '', search, messageIdSearch))}</Typography>
          </Row>
        )}
        {data?.footer && (
          <Row mt='16px' width='100%' justifyContent='flex-end'>
            <Typography>{handleFormat(data?.footer)}</Typography>
          </Row>
        )}
      </Column>
      {data?.buttons?.length > 0 && (
        <ButtonsTemplate>
          {data?.buttons?.map((button, idx) => (
            <Row key={idx} gap='4px' justifyContent='center' background='transparent'>
              <Row
                width='90%'
                boxShadow='0px 2px 0px 0px #0000000D'
                background='#fff'
                borderRadius='8px'
                justifyContent='center'
                alignItems='center'
                mt='4px'
                p='8px'
                gap='2px'
              >
                <Icon icon={buttonsTemplateDict[button?.button_type]?.icon} color='grey.300' />
                <Typography>({button?.text})</Typography>
              </Row>
            </Row>
          ))}
        </ButtonsTemplate>
      )}
    </Column>
  )
}

const ButtonsTemplate = styled(Column)`
  padding-bottom: 16px;
  background: transparent;
`

export default TemplateCard
