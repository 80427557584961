import { Avatar, Column, Row, Typography } from 'design-system'
import { useCallback, useMemo, useEffect } from 'react'
import HeaderButtons from 'components/specific/tickets/TicketHeader/HeaderButtons'
import { updateTicket } from 'services'
import SkeletonHeader from './SkeletonHeader'

const TicketContactHeader = ({
  ticket,
  loading,
  openDrawer,
  setOpenDrawer,
  openCloseOption,
  setOpenCloseOption,
  acceptTicket,
  setAcceptTicket,
  activeSearch,
  setActiveSearch,
  ...props
}) => {
  useEffect(() => {
    if (activeSearch) {
      setAcceptTicket(false)
      setOpenCloseOption(false)
    }
  }, [activeSearch])

  useEffect(() => {
    if (openCloseOption) {
      setAcceptTicket(false)
      setActiveSearch(false)
    }
  }, [openCloseOption])

  useEffect(() => {
    if (acceptTicket) {
      setOpenCloseOption(false)
      setActiveSearch(false)
    }
  }, [acceptTicket])
  const { id, status, solved_status, closed_at } = ticket || {}

  const updateSolvedTicket = useCallback(async (values) => await updateTicket(id, values), [id])

  const actionButtons = [
    {
      icon: 'Search',
      handler: () => setActiveSearch(!activeSearch),
      disabled: false,
      tooltip: 'Pesquisar',
      active: activeSearch,
      id: 'search',
    },
    {
      icon: 'ArrowSwitch',
      handler: () => setAcceptTicket(ticket),
      disabled: false,
      tooltip: 'Transferir conversa',
      active: acceptTicket,
      id: 'switch',
    },
    {
      icon: 'Check',
      handler: () => setOpenCloseOption(!openCloseOption),
      disabled: status === 'closed',
      tooltip: status === 'closed' ? 'Não é possível realizar a alteração, pois a conversa está encerrada.' : 'Encerrar conversa',
      active: openCloseOption,
      id: 'check',
    },
  ]

  const moreActions = useMemo(
    () => [
      {
        title: solved_status ? 'Marcar como não lido' : 'Marcar como lido',
        action: () => updateSolvedTicket({ manual_solved_status: !solved_status }),
      },
    ],
    [solved_status, updateSolvedTicket],
  )

  return (
    <Row
      p='8px 16px'
      border='1px solid #E8E8E8'
      borderLeft='none'
      borderRight='none'
      gap='8px'
      minHeight='75px'
      alignItems='center'
      justifyContent='space-between'
      background='#fff'
      {...props}
    >
      {loading ? (
        <SkeletonHeader />
      ) : (
        <>
          <Row gap='8px' alignItems='center' onClick={() => setOpenDrawer(!openDrawer)} className='cursor-pointer'>
            <Avatar square size='md' src={ticket?.contact?.picture} alt={ticket?.contact?.name} />
            <Column>
              <Typography fontSize='14px' fontWeight='600'>
                {ticket?.contact?.name}
              </Typography>
              <Typography fontSize='14px'>{` ${ticket?.attendant?.name ? `Responsável: ${ticket?.attendant?.name}` : 'Sem responsável atendente'
                } | ${ticket?.department?.name || 'Sem departamento'}`}</Typography>
            </Column>
          </Row>
          <Row gap='8px'>
            <HeaderButtons closed_at={closed_at} actions={actionButtons} moreActions={moreActions} />
          </Row>
        </>
      )}
    </Row>
  )
}

export default TicketContactHeader
