import TemplatePreviewCard from './TemplatePreviewCard'
import { Row, Card, Input, Column, Typography, Select } from 'design-system'

const PreviewTemplate = ({ errors, register, watch, name, control, setValue, templatesList }) => {
  const template = watch(name)
  const templateParamsName = `${name}_params`

  const params = watch(templateParamsName)

  const handleRenderParams = (length, n, r) => {
    return Array.from({ length }, (_, id) => (
      <Row key={id} mt='16px' width='100%' gap='16px'>
        <Row maxWidth='100px'>
          <Input
            key={template.name}
            label='Variável'
            error={errors?.params && errors?.params[id]?.param}
            value={`{{${id + 1}}}`}
            {...r(`${n}.${id + 1}.param`, { required: true })}
            readOnly
          />
        </Row>
        <Row flexGrow='1'>
          <Input
            key={template.name}
            label='Valor da variável'
            error={errors?.params && errors?.params[id]?.text}
            {...r(`${n}.${id + 1}.text`, { required: true })}
          />
        </Row>
      </Row>
    ))
  }

  const handleReplaceTemplateData = (data) => {
    if (params?.length > 0) {
      params.forEach((item) => {
        data.content = item.text ? data.content.replace(item.param, item.text) : data.content
      })
    }
    return data
  }

  return (
    <Row mt='16px' width='100%' gap='16px' minHeight='200px'>
      <Card width='50%'>
        <Row>
          <Select
            label='Buscar template'
            options={templatesList}
            keys={{ label: 'name', value: 'id' }}
            name={name}
            control={control}
            error={errors[name]}
            callBack={() => setValue(templateParamsName, [])}
            required
          />
        </Row>
        {template?.params_amount > 0 ? handleRenderParams(template?.params_amount, templateParamsName, register) : null}
      </Card>
      <Column alignItems='center' justifyContent='center' p='32px' background='#E8E8E8' width='50%' borderRadius='8px' gap='16px'>
        {template && (
          <Typography fontSize='18px' fontWeight='600'>
            Visualização do template
          </Typography>
        )}
        <TemplatePreviewCard template={handleReplaceTemplateData({ ...template })} />
      </Column>
    </Row>
  )
}

export default PreviewTemplate
