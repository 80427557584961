import { forwardRef } from 'react'
import styled from 'styled-components'
import { space, layout, typography, color, compose, border } from 'styled-system'

import Typography from '../Typography'
import Column from '../Column'
import Icon from 'design-system/Icon'
import Row from 'design-system/Row'
import PopoverEmoji from 'components/popovers/PopoverEmoji'
import PopoverTextVariables from 'components/popovers/PopoverTextVariables'
import { useClickout } from 'hooks'
import Skeleton from 'design-system/Skeleton'
import TextLabelHelper from 'design-system/TextLabelHelper'

const style = compose(space, layout, typography, color, border)

const Base = styled(Column)`
  padding: 8px;
  border-radius: 4px;
  border: ${({ theme: { colors }, error }) => `1px solid ${error ? colors.danger : colors.grey[50]}`};
  &:focus,
  &:hover {
    outline: none;
    border-color: ${({ theme: { colors }, disabled }) => (disabled ? colors.grey[50] : colors.blue[80])};
  }
  ${style};
`

const Input = styled.textarea`
  width: 100%;
  max-width: 100%;
  min-width: 100%;
  border-radius: 4px;

  height: 100%;
  min-height: 50px;
  border: none;
  resize: ${({ resize }) => resize};
  color: ${({ theme: { colors } }) => colors.grey[300]};
  &:disabled {
    background-color: #fff;
  }
  ${style};
`

const TextArea = forwardRef(
  ({ label, error, errorMessage, emojis, textVariables, setValue, getValues, disabled, containerProps, loading, ...props }, ref) => {
    const { triggerRef: triggerEmojiRef, elementRef: elementEmojiRef, openedState: openedStateEmoji } = useClickout(false)

    const { triggerRef: triggerTextVariablesRef, elementRef: elementTextVariablesRef, openedState: openedStateTextVariables } = useClickout(false)

    const handleChange = (value) => setValue(props?.name, `${getValues(props?.name)}${value}`)

    return loading ? (
      <Column width='100%' background='white' borderRadius='4px'>
        {label && <Skeleton width='100px' height='20px' />}
        <Skeleton mt='2px' p='15px' height='150px' {...containerProps} />
      </Column>
    ) : (
      <Column width='100%' background='white' borderRadius='4px' gap='4px'>
        <TextLabelHelper label={label} required={props.required} helper={props.helper} />
        <Base error={error} disabled={disabled} {...containerProps}>
          <Input ref={ref} disabled={disabled} {...props} />
          <Row gap='8px'>
            {emojis && (
              <Row position='relative'>
                <Icon ref={triggerEmojiRef} icon='Emoji' color='grey.300' cursor='pointer' />
                <PopoverEmoji elementRef={elementEmojiRef} top='20px' open={openedStateEmoji} onEmojiSelect={handleChange} />
              </Row>
            )}
            {textVariables && (
              <Row position='relative'>
                <Icon ref={triggerTextVariablesRef} icon='AddCircle' color='grey.300' cursor='pointer' />
                {openedStateTextVariables && (
                  <PopoverTextVariables top='20px' elementRef={elementTextVariablesRef} open={openedStateTextVariables} onTextSelect={handleChange} />
                )}
              </Row>
            )}
          </Row>
        </Base>
        {error && (
          <Typography ml='5px' variant='helper' color='danger'>
            {errorMessage || 'Campo obrigatório'}
          </Typography>
        )}
      </Column>
    )
  },
)

TextArea.defaultProps = {
  label: null,
  resize: 'initial',
  placeholder: 'Digite seu texto',
  emojis: false,
  textVariables: false,
  setValue: () => undefined,
  getValues: () => undefined,
}

export default TextArea
