import { useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'

import {
  Column,
  Form,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Typography,
  Spinner,

} from 'design-system'
import { notify } from 'helpers'
import Footer from './Footer'
import Category from './Category'

import { initialValues } from './constants'
import Model from './Model'
import PreviewTemplate from 'components/specific/templates/PreviewTemplateCard'
import { createTemplate } from 'services'

const ModalCreateTemplate = ({ open, onClose, onRefresh }) => {
  const [currentStep, setCurrentStep] = useState(0)
  const [loading, setLoading] = useState(false)

  const methods = useForm({ defaultValues: initialValues })

  const watchValues = methods.watch()

  const onSubmit = async (values) => {
    try {
      setLoading(true)
      values.tags = values?.tags?.map((t) => t.id)
      values.connection = values.connection.id
      values.language = values.language.value
      values.template_type = values.template_type.value

      values.broker_name = values.name
        ?.replaceAll(' ', '_')
        ?.replaceAll(/[^a-zA-Z0-9_]/g, '')
        ?.toLowerCase()

      if (values.header.header_type) {
        values.header = {
          header_type: values.header.header_type.value,
          text: values.header.text,
        }
      } else {
        delete values.header
      }

      await createTemplate(values)
      setLoading(false)
      notify.success('Template criado com sucesso.')
      onRefresh()
      setTimeout(() => onClose(), 1000)
    } catch {
      setLoading(false)
      notify.error('Não foi possível criar template. Verifique as informações.')
    }
  }

  const handleChangeNextStep = () => setCurrentStep((prev) => prev + 1)
  const handleChangePreviousStep = () => setCurrentStep((prev) => prev - 1)

  return (
    <Modal size='xl' open={open}>
      {loading &&
        <Row
          id='spinner'
          position='absolute'
          width='100%'
          justifyContent='center'
          bottom='16px'
        >
          <Spinner size='md' />
        </Row>
      }
      <FormProvider {...methods}>
        <Form onSubmit={methods.handleSubmit(onSubmit)}>
          <ModalHeader onClose={onClose}>
            <Typography fontSize='24px' fontWeight='600' color='primary'>
              Novo modelo de mensagem | Templates
            </Typography>
          </ModalHeader>
          <ModalBody>
            <Row gap='16px'>
              <Column width='100%' maxHeight='700px' overflow='auto'>
                {currentStep === 0 && <Category />}
                {currentStep === 1 && <Model />}
              </Column>
              <PreviewTemplate key='template' template={{ ...watchValues }} />
            </Row>
          </ModalBody>
          <ModalFooter>
            <Footer loading={loading} currentStep={currentStep} next={handleChangeNextStep} previous={handleChangePreviousStep} />
          </ModalFooter>
        </Form>
      </FormProvider>
    </Modal >
  )
}

export default ModalCreateTemplate
