/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState } from 'react'
import { colors } from 'configs'
import { Button, Column, Icon, Input, Row, TextArea } from 'design-system'
import { useFieldArray, useFormContext } from 'react-hook-form'

const TemplateTextVariants = ({ name, ...rest }) => {
  const {
    register,
    getValues,
    control,
    setValue,
    setFocus,
    formState: { errors },
  } = useFormContext()

  const [textIdx, setTextIdx] = useState({})
  const { fields, append, remove, replace } = useFieldArray({ name: `${name}_variants`, control })
  const handleCreateVariant = async (content) => {
    const newKey = fields?.length + 1
    setFocus(`${name}_variants.${fields.length}.text`)
    setValue(name, `${content}{{${newKey}}}`)
    await append({ key: `{{${newKey}}}`, text: '' })
  }

  const handleRemoveVariant = (content, indexToRemove, fieldToRemove) => {
    const newContent = content.replaceAll(fieldToRemove.key, '')
    remove(indexToRemove)
    setValue(name, newContent)
  }

  const findKeysInText = (keys, text) => {
    const keyRegex = new RegExp(keys.map(key => key.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')).join('|'), 'g')
    const matches = text.match(keyRegex) || []
    return [...new Set(matches)]
  }

  useEffect(() => {
    const content = getValues(name)
    const keys = fields.map(field => field.key)
    const foundKeys = findKeysInText(keys, content)
    const newFilteredFields = fields.filter(field => foundKeys.includes(field.key))
    if (newFilteredFields.length < fields.length && fields.length > 0) {
      const newFields = newFilteredFields.map((field, idx) => ({
        ...field,
        key: `{{${idx + 1}}}`,
        text: textIdx[`${field.id}.text`] || field.text,
      }))
      replace(newFields)
    }

  }, [getValues(name)])

  const handleChangeText = (value, index, fieldId) => {
    getValues(name).replace(fields[index].key, value)
    setValue(`${name}_variants.${index}.text`, value)
    setTextIdx({ ...textIdx, [`${fieldId}.text`]: value })
  }

  return (
    <Column {...rest}>
      <TextArea emojis setValue={setValue} getValues={getValues} error={errors?.[name]} required {...register(name, { required: true })} {...rest} />
      <Column mt={fields?.length > 0 ? '16px' : '0'} gap='16px'>
        {fields.map((field, index) => (
          <Row
            p='16px'
            borderRadius='8px'
            key={field.id}
            background={fields?.length > 0 ? colors.grey[50] : colors.white}
            gap='16px'
            alignItems='center'
          >
            <Input maxWidth='94px' label='Variável' {...register(`${name}_variants.${index}.key`)} disabled />
            <Input maxWidth='200px' tabIndex='1' label='Valor de exemplo' {...register(`${name}_variants.${index}.text`)}
              onChange={(e) => handleChangeText(e.target.value, index, field.id)}
            />
            <Row mt='16px' alignItems='center'>
              <Icon disabled={index !== fields.length - 1} icon='Trash' color='danger' onClick={() => handleRemoveVariant(getValues(name), index, field)} cursor='pointer' />
            </Row>
          </Row>
        ))}
      </Column>
      <Row mt='16px' justifyContent='flex-end'>
        <Button iconLeft='Plus' variant='text' color='secondary' onClick={() => handleCreateVariant(getValues(name))}>
          Adicionar variável
        </Button>
      </Row>
    </Column>
  )
}

export default TemplateTextVariants
