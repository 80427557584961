import { colors } from 'configs'
import { Icon, Input, Row } from 'design-system'
import { useRef } from 'react'
import { actionLabelByValue } from '../ActionsButton/options'

const ActionField = ({ fieldType, name, remove, register, position, move, errors }) => {
  const fieldRef = useRef()

  const fieldDict = {
    QUICK_REPLY: (fieldName, index) => (
      <>
        <Input
          label='Texto do botão'
          error={errors?.buttons?.[index] && errors?.buttons[index]?.text}
          {...register(`${fieldName}.text`, { required: true })}
        />
      </>
    ),
    PHONE_NUMBER: (fieldName, index) => (
      <>
        <Input
          label='Telefone'
          error={errors?.buttons?.[index] && errors?.buttons[index]?.phone}
          {...register(`${fieldName}.phone`, { required: true })}
        />
        <Input
          label='Texto do botão'
          error={errors?.buttons?.[index] && errors?.buttons[index]?.text}
          {...register(`${fieldName}.text`, { required: true })}
        />
      </>
    ),
    URL: (fieldName, index) => (
      <>
        <Input
          label='URL do botão'
          error={errors?.buttons?.[index] && errors?.buttons[index]?.url}
          {...register(`${fieldName}.url`, { required: true })}
        />
        <Input
          label='Texto do botão'
          error={errors?.buttons?.[index] && errors?.buttons[index]?.text}
          {...register(`${fieldName}.text`, { required: true })}
        />
      </>
    ),
  }

  const handleDragStart = (e, index) => {
    e.dataTransfer.setData('dragIndex', index)
    e.currentTarget.style.opacity = '0.5'
    e.currentTarget.style.border = `1px solid ${colors.primary}`
  }

  const handleDragEnd = (e) => {
    e.currentTarget.style.opacity = '1'
    e.currentTarget.style.border = `1px solid ${colors.grey[50]}`
  }

  const handleDragOver = (e) => {
    e.preventDefault()
    e.dataTransfer.dropEffect = 'move'
  }

  const handleDrop = (e, index) => {
    e.preventDefault()
    const dragIndex = e.dataTransfer.getData('dragIndex')
    if (dragIndex !== null && dragIndex !== undefined) {
      move(Number(dragIndex), index)
    }
  }

  return (
    <Row
      ref={fieldRef}
      draggable='true'
      onDragStart={(e) => handleDragStart(e, position)}
      onDragEnd={handleDragEnd}
      onDragOver={(e) => handleDragOver(e, position)}
      onDrop={(e) => handleDrop(e, position)}
      p='16px'
      gap='16px'
      borderRadius='8px'
      background={colors.grey[50]}
      alignItems='center'
    >
      <Input maxWidth='150px' label='Tipo de botão' {...register(`${name}.button_type`)} disabled value={actionLabelByValue[fieldType]} />
      {fieldDict[fieldType](name, position)}
      <Row>
        <Icon icon='Drag' color='text' cursor='grab' />
        <Icon icon='Trash' color='danger' onClick={remove} cursor='pointer' />
      </Row>
    </Row>
  )
}

export default ActionField
