import { format } from 'date-fns'

import { Column, Icon, Row, Tooltip, Typography } from 'design-system'

import typeMessages from './TypeMessages'

import { colors } from 'configs'

const PreviewTemplate = ({ template }) => {
  const { content, template_type, footer, header, buttons, content_variants } = template || {}

  const buttonsIcon = {
    QUICK_REPLY: 'Share',
    PHONE_NUMBER: 'Phone',
    URL: 'Launch',
  }

  const handleReplaceTemplateData = (content) => {
    if (content_variants?.length > 0) {
      content_variants.forEach(({ key, text }) => {
        content = text ? content?.replace(key, text) : content
      })
    }
    return content
  }

  return (
    <Column width='100%' maxWidth='342px' p='32px' justifyContent='center' background={colors.grey[25]} borderRadius='8px'>
      <Typography fontSize='18px' fontWeight='600' textAlign='center'>
        Visualização do template
      </Typography>
      <Column mt='16px' p='8px' borderRadius={buttons?.length > 0 ? '8px 8px 0 0' : '8px'} backgroundColor={colors.violet[100]}>
        <Row>
          <Typography fontWeight='600' color='grey.400'>
            {header?.text}
          </Typography>
        </Row>
        <Row mt='8px'>
          {typeMessages[template_type?.value] ? (
            typeMessages[template_type?.value](handleReplaceTemplateData(content))
          ) : (
            <Typography width='100%' color='grey.400' textAlign='center'>
              Selecione o tipo de template
            </Typography>
          )}
        </Row>
        <Row justifyContent='flex-end' alignItems='center'>
          <Typography>{footer}</Typography>
        </Row>
        <Row mt='8px' gap='4px' justifyContent='flex-end' alignItems='center'>
          <Typography color='grey.200'>{format(new Date(), 'hh:mm')}</Typography>
          <Icon icon='CheckAll' color='grey.200' />
        </Row>
      </Column>
      <Column mt='1px' gap='1px'>
        {buttons?.map(({ button_type, text, phone, url }, index) => (
          <Row key={`${button_type}_${index}`} justifyContent='center' alignItems='center' p='8px' background={colors.violet[100]}>
            <Tooltip title={phone || url || text}>
              <Icon icon={buttonsIcon[button_type]} color='grey.400' />
              <Typography ml='4px'>{text}</Typography>
            </Tooltip>
          </Row>
        ))}
      </Column>
    </Column>
  )
}

export default PreviewTemplate
