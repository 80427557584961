export const options = [
  {
    label: 'URL',
    value: 'URL',
  },
  {
    label: 'Telefone',
    value: 'PHONE_NUMBER',
  },
  {
    label: 'Respostas rápidas',
    value: 'QUICK_REPLY',
  },
]

export const buttonsActionsValuesDict = {
  QUICK_REPLY: { button_type: 'QUICK_REPLY', text: '' },
  PHONE_NUMBER: { button_type: 'PHONE_NUMBER', text: '', phone: '' },
  URL: { button_type: 'URL', text: '', url: '' },
}

export const actionTypesLimit = {
  QUICK_REPLY: 10,
  PHONE_NUMBER: 1,
  URL: 2,
  TOTAL: 10,
}

export const actionLabelByValue = {
  PHONE_NUMBER: 'Telefone',
  QUICK_REPLY: 'Respostas rápidas',
  URL: 'URL',
}

