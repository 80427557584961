import { useFormContext } from 'react-hook-form'

import { Button, Row } from 'design-system'

import { stepRequiredFields } from '../constants'

const Footer = ({ currentStep, next, previous, loading }) => {
  const { trigger } = useFormContext()

  const handleValidateStep = async () => {
    const valid = await trigger(stepRequiredFields)

    if (valid) next()
  }

  return (
    <Row gap='8px'>
      {currentStep > 0 && (
        <Button variant='outlined' onClick={previous}>
          Voltar
        </Button>
      )}
      {currentStep !== 1 && <Button onClick={handleValidateStep}>Continuar</Button>}
      {currentStep === 1 && <Button disabled={loading} type='submit'>Salvar template</Button>}
    </Row>
  )
}

export default Footer
