import { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { colors } from 'configs'
import { Column, Row, Spinner, Input, Icon } from 'design-system'
import TicketHeader from 'components/specific/tickets/TicketHeader'
import HeaderSelectedMessage from 'components/specific/tickets/TicketHeader/HeaderSelectedMessage'
import TicketMessage from 'components/specific/tickets/TicketMessage'
import TicketContactDrawer from 'components/specific/tickets/TicketContactDrawer'
import { ModalExpandImage } from 'components'

import { formatDateLabel, notify } from 'helpers'
import {
  markMessageRead,
  showTicketInfo,
  showTicketMessagesList,
  updateTicket,
  forwardMessages,
  searchMessages,
} from 'services'
import CloseTicketHeader from './CloseTicketHeader'
import { ModalAcceptTicket } from 'components'
import SkeletonMessages from './SkeletonMessages'

import { useTickets, useDebounce } from 'hooks'
import CustomMessage from './CustomMessage'
import { format } from 'date-fns'
import TicketMessageInput from 'components/specific/tickets/TicketMessageInput'
import DateSeparator from './DateSeparator'
import * as S from './styles'

const TicketContainer = ({ ticketId }) => {
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const [spinner, setSpinner] = useState(false)
  const [openDrawer, setOpenDrawer] = useState(true)
  const [openCloseOption, setOpenCloseOption] = useState(false)
  const [acceptTicket, setAcceptTicket] = useState(false)
  const [activeSearch, setActiveSearch] = useState(false)
  const [messages, setMessages] = useState()
  const [count, setCount] = useState()
  const replyMenuState = useState(null)
  const [showCheckbox, setShowCheckbox] = useState(false)
  const [expand, setExpand] = useState(null)
  const [messageSelected, setMessageSelected] = useState([])
  const [search, setSearch] = useState('')
  const [loadingSearchMessages, setLoadingSearchMessages] = useState(false)
  const [matchingItemsCount, setMatchingItemsCount] = useState()
  const [messageIdSearch, setMessageIdSearch] = useState()
  const [messageSearchData, setMessageSearchData] = useState([])
  const [messageIdSearchOrderIdx, setMessageIdSearchOrderIdx] = useState()
  const [withScroll, setWithScroll] = useState(false)
  const [dataSend, setDataSend] = useState(null)

  const containerMessageRef = useRef(null)
  const debounceSearch = useDebounce(search, 1500)

  const { state, dispatch, actionMessagesTypes, actionSelectedTicketTypes, actionTicketTypes, actionRepliedMessage } = useTickets()


  useEffect(() => {
    if (messageIdSearch && messages.length === count && withScroll) {
      const idx = messages.findIndex((message) => message.id === messageIdSearch)
      if (idx !== -1) {
        setWithScroll(false)
        scrollToMessage(messageIdSearch)
      }
    }
  }, [messages, messageIdSearch, withScroll])


  useEffect(() => {
    handleCancelSearch();
    setActiveSearch(false);
    ticketId && fetchTicket(ticketId, 20)
    setOpenCloseOption(false)

    return () => {
      dispatch({ type: actionMessagesTypes.RESET_MESSAGES })
      dispatch({
        type: actionRepliedMessage.REMOVE_REPLY,
      })
      replyMenuState[1](null)
      handleCancel()
    }
  }, [ticketId])


  const fetchMarkMessageRead = async (id) => {
    const { data } = await markMessageRead(id)
    data?.ticket_data.status === state.current_status &&
      dispatch({
        type: actionTicketTypes.UPDATE_TICKET,
        payload: data?.ticket_data,
      })
  }

  useEffect(() => {
    if (!loading && messages?.length) {
      fetchMarkMessageRead(ticketId)
    }
  }, [loading, messages])


  const fetchTicket = async (id, limit, refresh) => {
    try {
      if (refresh) {
        setSpinner(true)
      } else {
        setLoading(true)
      }

      const [{ data: ticket }, { data: messages }] = await Promise.all([showTicketInfo(id), showTicketMessagesList(id, { limit })])


      const groupedMessages = messages.results.reduce((acc, curr) => {
        const key = format(curr.created_at, 'dd/MM/yyyy')

        acc[key] ? acc[key].push(curr) : (acc[key] = [{ ...curr, key }])

        return acc
      }, {})

      dispatch({
        type: actionMessagesTypes.LOAD_MESSAGES,
        payload: groupedMessages,
      })

      dispatch({
        type: actionSelectedTicketTypes.LOAD_SELECTED_TICKET,
        payload: ticket,
      })

      setMessages(messages.results)
      setCount(messages.count)
    } catch {
      notify.error('Não foi possível carregar mensagens do contato.')
    } finally {
      if (refresh) {
        setSpinner(false)
      } else {
        setLoading(false)
      }
    }
  }

  const handlerUpdateTicket = async (id, values) => {
    try {
      setLoading(true)

      const { data } = await updateTicket(id, values)

      dispatch({ type: actionSelectedTicketTypes.UPDATE_SELECTED_TICKET, payload: data })

      values.send_satisfaction_survey
        ? notify.success(
          'Conversa encerrada e pesquisa de satisfação enviada ao contato com sucesso!',
        )
        : notify.success('Conversa encerrada com sucesso!')

      setOpenCloseOption(false)
    } catch {
      notify.error('Não foi possível atualizar conversa.')
    } finally {
      setLoading(false)
    }
  }

  const handleSelectedForwards = (messageId) => {
    const newMessageSelected = [...messageSelected]
    const idx = newMessageSelected.indexOf(messageId)
    if (idx !== -1) {
      newMessageSelected.splice(idx, 1)
    } else {
      newMessageSelected.push(messageId)
    }
    setMessageSelected(newMessageSelected)
  }

  const handleCancel = () => {
    setMessageSelected([])
    setShowCheckbox(false)
  }

  const handleForwardsContactsClick = async (contacts) => {
    try {
      setLoading(true)

      const { data } = await forwardMessages({
        connection: state.selected_ticket.connection.id,
        contacts,
        ticket_messages: messageSelected.sort((a, b) => a - b)
      })
      if (contacts.length === 1) {
        const idx = data.tickets.findIndex(item => item.contact.id === contacts[0])
        if (idx !== -1) {
          navigate(`/tickets/${data.tickets[idx].id}`)
        }
      }
      handleCancel()
    } catch {
      notify.error('Não foi possível encaminhar as mensagens.')
    } finally {
      setLoading(false)
    }
  }

  const handleClickCopySelected = () => {
    let text = ''
    messageSelected.forEach((messageId) => {
      const message = messages.find((m) => m.id === messageId);
      if (message.body) {
        if (text) {
          text = `${text}\n\n${message.body.text}`
        } else {
          text = message.body.text
        }
      }
    })

    navigator.clipboard.writeText(text);
    handleCancel()
    notify.success('Mensagens copiadas com sucesso!')
  }

  const handleRightClick = (e) => e.preventDefault();

  const fetchTicketMessagesScroll = async ({ offset, limit }) => {
    setSpinner(true)
    const { data } = await showTicketMessagesList(ticketId, { limit, offset })
    const newDataSend = []
    if (dataSend) {
      newDataSend.push(dataSend)
    }
    const merge = [...newDataSend, ...messages, ...data.results]
    const groupedMessages = merge.reduce((acc, curr) => {
      const key = format(curr.created_at, 'dd/MM/yyyy')
      acc[key] ? acc[key].push(curr) : (acc[key] = [{ ...curr, key }])
      return acc
    }, {})
    dispatch({
      type: actionMessagesTypes.LOAD_MESSAGES,
      payload: groupedMessages,
    })

    setDataSend(null)
    setMessages(merge)
    setSpinner(false)
  }

  const handleScroll = (event) => {
    const { scrollTop, scrollHeight, clientHeight } = event.target;
    const isTop = scrollTop - (clientHeight - scrollHeight) <= 10
    if (isTop && count !== messages.length) {
      fetchTicketMessagesScroll({ offset: messages.length, limit: 20 })
    }
  }

  useEffect(() => {
    handleSearchMessage(debounceSearch)
  }, [debounceSearch])

  const handleSearchMessage = async (content) => {
    if (content) {
      try {
        setLoadingSearchMessages(true)
        const { data: { results } } = await searchMessages({
          content,
          ticket: ticketId,
          page_size: 9999
        })
        const resultsLength = results.length
        if (!resultsLength) {
          notify.info('Nenhuma mensagem encontrada.')
          return
        }
        const idx = 0
        setMatchingItemsCount(resultsLength)
        setMessageIdSearch(results[idx].ticket_message_id)
        setMessageSearchData(results)
        setMessageIdSearchOrderIdx(idx)
        setWithScroll(true)
        if (resultsLength > 0) {
          fetchTicket(ticketId, 9999, true)
        }
      } catch {
        notify.error('Não foi possível buscar mensagens.')
      } finally {
        setLoadingSearchMessages(false)
      }
    } else {
      handleCancelSearch()
    }
  }

  const handleCancelSearch = () => {
    setMessageSearchData([])
    setMessageIdSearch(undefined)
    setMatchingItemsCount(undefined)
    setWithScroll(false)
    setSearch('')
  }

  const handleSearchNavigate = (idx) => {
    if (!isNaN(idx)) {
      let sanitizeIdx = idx
      if (idx > messageSearchData.length - 1) {
        sanitizeIdx = 0
      }
      if (idx < 0) {
        sanitizeIdx = messageSearchData.length - 1
      }
      if (sanitizeIdx !== -1 && messageSearchData.length > 0) {
        setMessageIdSearch(messageSearchData[sanitizeIdx].ticket_message_id)
        setMessageIdSearchOrderIdx(sanitizeIdx)
        setWithScroll(true)
      }
    }
  }


  const addLeadingZero = (num) => (num < 10 ? `0${num}` : `${num}`);

  const scrollToMessage = (messageId) => {
    const element = document.getElementById(`message_${messageId}`);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  };

  const handleChangeDataSend = (dataSendParam) => setDataSend(dataSendParam)

  return (
    <Row width='100%'>
      <Column position='relative' width='100%'>
        {spinner &&
          <Row
            id='spinner'
            position='absolute'
            top={activeSearch ? '125px' : '75px'}
            width='100%'
            justifyContent='center'
          >
            <Spinner size='sm' />
          </Row>
        }
        {!showCheckbox &&
          <>
            <TicketHeader
              loading={loading}
              ticket={state?.selected_ticket}
              openDrawer={openDrawer}
              setOpenDrawer={setOpenDrawer}
              openCloseOption={openCloseOption}
              setOpenCloseOption={setOpenCloseOption}
              acceptTicket={acceptTicket}
              setAcceptTicket={setAcceptTicket}
              setActiveSearch={(value) => setActiveSearch(value)}
              activeSearch={activeSearch}
            />
            {activeSearch &&
              <Row
                width='100%'
                display='flex'
                flexDirection='row-reverse'
              >
                <Row
                  width='500px'
                  height='50px'
                  background={colors.grey[90]}
                  padding='8px'
                  display='flex'
                  alignItems='center'
                  position='relative'
                >
                  <Input
                    hideIconClose
                    type='search'
                    icon='Search'
                    iconProps={{ color: 'grey.200' }}
                    placeholder='Pesquisar na conversa'
                    value={search}
                    onChange={(event) => {
                      handleCancelSearch();
                      setSearch(event.target.value.toLowerCase())
                    }}
                  />
                  {!loadingSearchMessages &&
                    matchingItemsCount !== undefined &&
                    !!messageSearchData.length &&
                    <Row
                      position='absolute'
                      right='140px'
                      fontSize='14px'
                      color={colors.grey[100]}
                      style={{ userSelect: 'none' }}
                    >
                      {`${addLeadingZero(messageIdSearchOrderIdx + 1)}/${addLeadingZero(matchingItemsCount)}`}
                    </Row>
                  }
                  <Icon
                    icon='Arrow'
                    marginLeft='24px'
                    cursor='pointer'
                    color={colors.grey[300]}
                    onClick={() => handleSearchNavigate(messageIdSearchOrderIdx + 1)}
                  />
                  <Icon
                    icon='Arrow'
                    cursor='pointer'
                    direction='bottom'
                    marginLeft='8px'
                    color={colors.grey[300]}
                    onClick={() => handleSearchNavigate(messageIdSearchOrderIdx - 1)}
                  />
                  <S.Separator />
                  <Icon
                    icon='Close'
                    color={colors.grey[300]}
                    cursor='pointer'
                    marginLeft='8px'
                    onClick={() => {
                      setActiveSearch(false)
                      handleCancelSearch()
                    }}
                  />
                </Row>
              </Row>
            }
          </>
        }
        {showCheckbox &&
          <HeaderSelectedMessage
            onClickCopySelected={handleClickCopySelected}
            onCancel={handleCancel}
            messageSelected={messageSelected}
            onForwardsContactsClick={handleForwardsContactsClick}
          />
        }
        {loading ? (
          <SkeletonMessages />
        ) : (
          <Column
            background='#FAFAFB'
            borderTop='none'
            alignItems='center'
            width='100%'
            height='100%'
            overflow='hidden'
            onContextMenu={handleRightClick}
          >
            {openCloseOption && (
              <CloseTicketHeader
                ticket={state.selected_ticket}
                setOpenCloseOption={setOpenCloseOption}
                handlerUpdateTicket={handlerUpdateTicket}
                loading={loading}
              />
            )}
            <Column
              onScroll={handleScroll}
              width='100%'
              flexDirection='column-reverse'
              height='80%'
              overflow='auto'
            >
              {Object.keys(state.messages)
                .sort((itemA, itemB) => formatDateLabel(itemB) - formatDateLabel(itemA))
                .map((date, idx) => (
                  <Column key={`${date}_${idx}`} position='relative' p='10px 10px 0' >
                    {state?.selected_ticket?.closed_at && (
                      <CustomMessage
                        message={{
                          text: `*Conversa foi finalizada*\n${format(state?.selected_ticket?.closed_at, 'dd/MM/yyyy - HH:mm')}`,
                          message_type: 'text',
                          sender_type: 'internal',
                        }}
                      />
                    )}
                    <Column flexDirection='column-reverse' gap='8px'>
                      {state.messages[date].map((message, index) => (
                        <TicketMessage
                          messageIdSearch={messageIdSearch}
                          search={search}
                          setExpand={setExpand}
                          showCheckbox={showCheckbox}
                          onSelectedForwards={handleSelectedForwards}
                          onClickForward={() => setShowCheckbox(true)}
                          key={`${message?.id}_${index}`}
                          containerMessageRef={containerMessageRef}
                          index={index}
                          replyMenuState={replyMenuState}
                          selectedticket={state?.selected_ticket}
                          message={message}
                          dispatch={dispatch}
                          messageSelected={messageSelected}
                          actionRepliedMessage={actionRepliedMessage}
                        />
                      ))}
                      <DateSeparator date={date} />
                    </Column>
                  </Column>
                ))}
            </Column>
            <TicketMessageInput
              state={state}
              dispatch={dispatch}
              setAcceptTicket={setAcceptTicket}
              onChangeDataSend={handleChangeDataSend}
            />
          </Column>
        )}
      </Column>
      {openDrawer && <TicketContactDrawer ticket={state.selected_ticket} openDrawer={openDrawer} setOpenDrawer={setOpenDrawer} loading={loading} />}
      {acceptTicket && (
        <ModalAcceptTicket
          loading={loading}
          setLoading={setLoading}
          ticket={acceptTicket}
          open={acceptTicket}
          dispatch={dispatch}
          actionTypes={actionSelectedTicketTypes}
          onClose={() => setAcceptTicket(false)}
        />
      )}
      {expand && <ModalExpandImage closeClickOut open={expand} onClose={() => setExpand(null)} data={expand} />}
    </Row>
  )
}

export default TicketContainer
