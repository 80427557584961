import styled from "styled-components";
import { colors } from 'configs'

export const Separator = styled.div`
  height: 100%;
  width: 1px;
  background: ${colors.white};
  margin: 0 8px;
`

