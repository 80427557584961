import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'

import {
  Form,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Input,
  Row,
  Button,
  Typography,
  TextArea,
  Select,
  Column,
  Checkbox,
  Spinner,
  RadioButton,
  Card,
} from 'design-system'

import { createConnection, showConnectionInfo, updatedConnection } from 'services'
import { notify } from 'helpers'
import { colors, connectionsTypeOptions, satisfactionSurveyModeOptions } from 'configs'

const ModalConnections = ({ onClose, connection, dispatch, actionTypes, departmentsList, ...props }) => {
  const [loading, setLoading] = useState(false)
  const [copySuccess, setCopySuccess] = useState(false)

  const {
    register,
    handleSubmit,
    reset,
    control,
    setValue,
    getValues,
    watch,
    formState: { errors },
  } = useForm()

  const initialValues = {
    name: '',
    active: true,
    department_radio: 'no_default_department',
    satisfaction_survey_mode: satisfactionSurveyModeOptions[0],
    connection_type: connectionsTypeOptions[0],
  }

  const hasDefaultDepartment = watch('department_radio')

  useEffect(() => {
    connection?.id && handleGetInfo()

    reset(initialValues)

    return () => {
      setCopySuccess(false)
      reset(initialValues)
    }
  }, [connection])

  const onSubmit = async (values) => {
    try {
      setLoading(true)
      const formattedData = {
        ...values,
        connection_type: values.connection_type?.value,
        default_department: values.department_radio === 'has_default_department' ? values.default_department?.id : null,
        send_department_options: values.department_radio === 'send_department_options',
        satisfaction_survey_mode: values?.satisfaction_survey_mode?.value,
      }

      const { data } = connection?.id ? await updatedConnection(connection?.id, formattedData) : await createConnection(formattedData)

      dispatch({
        type: connection?.id ? actionTypes.UPDATE_ITEM : actionTypes.ADD_ITEM,
        payload: data,
        tab: 'connections',
      })
      notify.success(connection?.id ? 'Conexão salva com sucesso.' : 'Nova conexão criado com sucesso.')
      onClose()
    } catch (err) {
      if (err?.response?.data?.detail[0]) return notify.error(err?.response?.data?.detail[0])

      notify.error('Não foi possível salvar configuração da conexão.')
    } finally {
      setLoading(false)
    }
  }

  const handleGetInfo = async () => {
    try {
      setLoading(true)

      const { data } = await showConnectionInfo(connection?.id)

      const formattedData = {
        ...data,
        connection_type: connectionsTypeOptions.find((co) => co.value === data.connection_type),
        department_radio: data.default_department
          ? 'has_default_department'
          : data.send_department_options
            ? 'send_department_options'
            : 'no_default_department',
        default_department: departmentsList.find((dp) => dp.id === data.default_department),
        satisfaction_survey_mode: satisfactionSurveyModeOptions.find((sa) => sa.value === data.satisfaction_survey_mode),
      }

      reset(formattedData)
    } catch {
      notify.error('Não foi possível buscar dados da conexão.')
    } finally {
      setLoading(false)
    }
  }

  const handleCopyUUID = () => {
    navigator.clipboard.writeText(connection?.uuid)
    setCopySuccess(true)
  }

  return (
    <Modal open={connection} onClose={onClose} {...props}>
      <ModalHeader onClose={onClose}>
        <Typography variant='title' color='primary'>
          {connection?.id ? 'Editar conexão' : 'Nova conexão'}
        </Typography>
      </ModalHeader>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <ModalBody>
          {loading ? (
            <Column alignItems='center' justifyContent='center' height='300px'>
              <Spinner />
            </Column>
          ) : (
            <Column>
              <Typography>Configure as opções de conexão para integrar com a API.</Typography>

              <Row mt='16px' gap='16px' maxWidth={['100%', '100%', '25%', '25%']}>
                <Select
                  label='Tipo de conexão'
                  options={connectionsTypeOptions}
                  name='connection_type'
                  error={errors?.connection_type}
                  control={control}
                  required
                  keys={{ label: 'label', value: 'id' }}
                  isDisabled={connection?.id}
                />
              </Row>
              <Row mt='16px' gap='16px'>
                <Column maxWidth='160px'>
                  <Input label='Nome' error={errors?.name} disabled={loading} {...register('name', { required: true })} />
                </Column>

                <Column width='100%'>
                  <Input
                    label='Identificador único'
                    error={errors?.uuid}
                    disabled={loading || connection?.id}
                    {...(connection?.id && { icon: 'Copy' })}
                    {...(connection?.id && {
                      iconProps: {
                        size: 'sm',
                        color: 'grey.300',
                        cursor: 'pointer',
                        onClick: handleCopyUUID,
                      },
                    })}
                    {...register('uuid', { required: true })}
                  />
                  {copySuccess && (
                    <Typography variant='caption' color='success'>
                      Copiado com sucesso!
                    </Typography>
                  )}
                </Column>
              </Row>
              <Row mt='16px'>
                <TextArea
                  label='Mensagem de saudação'
                  height='180px'
                  error={errors?.greeting_message}
                  setValue={setValue}
                  getValues={getValues}
                  emojis
                  textVariables
                  {...register('greeting_message')}
                />
              </Row>
              <Column mt='16px' gap='8px'>
                <Typography>Escolha o departamento padrão para esta conexão</Typography>
                <Card p='8px' gap='8px'>
                  <RadioButton type='radio' {...register('department_radio')} value='has_default_department' label='Definir departamento padrão' />
                  {hasDefaultDepartment === 'has_default_department' && (
                    <Column maxWidth='200px'>
                      <Select
                        label='Departamento'
                        options={departmentsList}
                        name='default_department'
                        error={errors?.default_department}
                        control={control}
                        required
                        keys={{ label: 'name', value: 'id' }}
                      />
                    </Column>
                  )}

                  <RadioButton
                    type='radio'
                    {...register('department_radio')}
                    value='send_department_options'
                    label='Permitir escolha de departamento durante a conversa'
                  />
                  <RadioButton type='radio' {...register('department_radio')} value='no_default_department' label='Não definir departamento padrão' />
                </Card>

                <Column gap='8px' mt='16px'>
                  <Checkbox
                    label='Enviar alteração de departamento para o contato durante a conversa'
                    {...register('send_department_change_to_contact')}
                  />
                  <Checkbox
                    label='Enviar alteração de atendente para o contato durante a conversa'
                    {...register('send_attendant_change_to_contact')}
                  />
                </Column>
              </Column>
              <Column mt='16px' p='16px 0' borderTop={`1px solid ${colors.grey[50]}`}>
                <Typography fontWeight='600'>Configuração de pesquisa de satisfação</Typography>
                <Typography mt='8px'>Escolha quando a pesquisa de satisfação será enviada ao finalizar um atendimento</Typography>
                <Row mt='4px' width='180px'>
                  <Select name='satisfaction_survey_mode' control={control} options={satisfactionSurveyModeOptions} required={true} />
                </Row>
              </Column>
            </Column>
          )}
        </ModalBody>
        <ModalFooter>
          <Button maxWidth='100px' variant='text' color='grey.500' onClick={onClose}>
            Cancelar
          </Button>
          <Button maxWidth='70px' type='submit' color={loading ? 'grey.50' : 'primary'} disabled={loading}>
            Salvar
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  )
}

export default ModalConnections
