import { Card, Column, Input, Select } from 'design-system'
import { useFieldArray, useFormContext } from 'react-hook-form'
import { headerTypesOptions } from '../constants'
import { ActionField, ActionsButton, TemplateTextVariants } from 'components/specific/templates/CreateTemplate'
import headerTypeDict from './headerType'

const Model = () => {
  const {
    control,
    register,
    watch,
    setValue,
    getValues,
    formState: { errors },
  } = useFormContext()

  const { fields, append, remove, move } = useFieldArray({ name: 'buttons', control })

  const headerTypeWatch = watch('header.header_type')

  const countTypeLimit = fields.reduce((acc, button) => {
    acc[button?.button_type] = (acc[button?.button_type] || 0) + 1
    return acc
  }, {})

  return (
    <Column gap='16px' width='100%'>
      <Card p='16px'>
        <Column gap='16px'>
          <Select
            maxWidth='200px'
            label='Selecione o tipo de cabeçalho'
            options={headerTypesOptions}
            control={control}
            callBack={() => setValue('header.text', '')}
            {...register('header.header_type')}
          />
          {headerTypeWatch?.value && headerTypeDict[headerTypeWatch.value]({ setValue, getValues, register })}
          <TemplateTextVariants label='Insira o texto da sua mensagem' name='content' />
          <Input label='Texto do rodapé' placeholder='Digite seu texto' {...register('footer')} />
        </Column>
      </Card>
      <Card>
        <ActionsButton append={append} countTypeLimit={countTypeLimit} limit={fields?.length === 10} remove={remove} />
        <Column mt='16px' gap='16px'>
          {fields.map((field, index) => (
            <ActionField
              key={field.id}
              fieldType={field.button_type}
              remove={() => remove(index)}
              name={`buttons.${index}`}
              register={register}
              position={index}
              move={move}
              errors={errors}
            />
          ))}
        </Column>
      </Card>
    </Column>
  )
}

export default Model
