import styled, { css } from 'styled-components'

import Row from '../Row'
import { colors } from 'configs'

const disabledVariant = css`
  background: ${colors.grey[50]};
`

const ListItem = styled(Row)`
  width: 100%;
  color: #656565;
  font-size: 16px;
  transition: all 0.5s ease;
  padding: 8px;
  gap: 8px;
  border-top: 1px solid ${colors.blue[50]};
  user-select: none;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  background: ${({ active, disabled }) => (disabled ? colors.grey[50] : active ? colors.blue[40] : 'transparent')};

  & * {
    cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  }

  &:hover {
    background: ${({ disabled }) => !disabled && colors.blue[40]};
  }

  ${({ disabled }) => disabled && disabledVariant}
`

export default ListItem
