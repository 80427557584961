import { forwardRef, useState } from 'react'
import styled from 'styled-components'

import Icon from 'design-system/Icon'
import Typography from 'design-system/Typography'
import { colors } from 'configs'
import { getThemeColor } from 'helpers'
import TextLabelHelper from 'design-system/TextLabelHelper'

const Base = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`
const Container = styled.div`
  display: flex;
`

const CounterButton = styled.button`
  max-height: 32px;
  padding: 8px;
  cursor: pointer;
  background-color: ${colors.white};
  border: 1px solid ${colors.grey[50]};

  &:first-child {
    border-radius: 4px 0 0 4px;
  }

  &:last-child {
    border-radius: 0px 4px 4px 0;
  }

  &:hover {
    border: ${({ color }) => `1px solid ${getThemeColor(color)}`};
  }
`

const CounterContainer = styled.div`
  display: flex;
  flex-shrink: 1;
  align-items: center;
  max-height: 32px;
  padding: 0 4px;
  cursor: pointer;
  border-top: 1px solid ${colors.grey[50]};
  border-bottom: 1px solid ${colors.grey[50]};
`

const Counter = forwardRef(({ color, hasLimit, hasNegative, negativeLimit, limit, label, required, helper, ...rest }, ref) => {
  const [counter, setCounter] = useState(0)

  const handleCounterAdd = () => (!hasLimit || !limit || counter < limit) && setCounter((prev) => prev + 1)

  const handleCounterLess = () => {
    if (!hasNegative && counter > 0) {
      setCounter((prev) => prev - 1)
    } else if (hasNegative && (!negativeLimit || counter > negativeLimit)) {
      setCounter((prev) => prev - 1)
    }
  }

  return (
    <Base ref={ref} alignItems='center' justifyContent='center' borderRadius='4px' {...rest}>
      <TextLabelHelper label={label} required={required} helper={helper} />
      <Container>
        <CounterButton type='button' color={color} onClick={handleCounterLess}>
          <Icon size='14' icon='Less' color='secondary' />
        </CounterButton>
        <CounterContainer className='cursor-pointer'>
          <Typography minWidth='24px' fontSize='14px' textAlign='center' fontWeight='600' color='secondary' notSelectable>
            {counter}
          </Typography>
        </CounterContainer>
        <CounterButton type='button' color={color} onClick={handleCounterAdd}>
          <Icon size='14' icon='Plus' color='secondary' />
        </CounterButton>
      </Container>
    </Base>
  )
})

Counter.defaultProps = {
  color: 'primary',
  hasNegative: false,
  negativeLimit: 0,
  hasLimit: false,
  limit: 0,
}

export default Counter
