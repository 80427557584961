import { Button, Column, ListItem, Popover } from 'design-system'
import { useClickout } from 'hooks'
import { actionTypesLimit, buttonsActionsValuesDict, options } from './options'

const ActionsButton = ({ append, countTypeLimit, limit, remove }) => {
  const { triggerRef, elementRef, openedState, setOpenedState } = useClickout(false)

  const handleCleanFields = () => {
    remove()
    setOpenedState(false)
  }

  return (
    <Column position='relative'>
      <Button ref={triggerRef} iconRight='Arrow' iconProps={{ direction: 'bottom' }} iconLeft='Plus' variant='outlined' color='secondary'>
        Adicionar botão
      </Button>
      {openedState && (
        <Popover open={openedState} ref={elementRef} width='180px' bottom='35px'>
          <ListItem onClick={() => handleCleanFields()}>Nenhum</ListItem>
          {options.map(({ label, value }, index) => {
            const disabled = countTypeLimit[value] === actionTypesLimit[value] || limit
            return (
              <ListItem
                key={`${value}_${index}`}
                onClick={() => (disabled ? undefined : append(buttonsActionsValuesDict[value]))}
                disabled={disabled}
              >
                {label}
              </ListItem>
            )
          })}
        </Popover>
      )}
    </Column>
  )
}

export default ActionsButton
