import { useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'

import CategorySelection from 'components/specific/templates/CreateTemplate/CategorySelection'
import { Card, Checkbox, Column, Input, Row, Select, SelectAsyncCreate, Typography } from 'design-system'

import { notify } from 'helpers'
import { createTemplateTag, showConnectionsListSelect, showTemplateTagsList } from 'services'
import { categoriesOptions, languageOptions, templateTypesOptions } from '../constants'

const Category = () => {
  const [connections, setConnections] = useState([])

  const {
    control,
    register,
    formState: { errors },
    setValue,
    getValues,
    watch
  } = useFormContext()

  useEffect(() => {
    getConnectionsOptions()
    getTagsList()
  }, [])

  const getConnectionsOptions = async () => {
    try {
      const { data } = await showConnectionsListSelect({
        page_size: 100,
        active: true,
      })

      if (data?.results?.length === 1) setValue('connection', data.results[0])

      setConnections(data.results)
    } catch {
      notify.error('Não foi possível resgatar listagem de conexões.')
    }
  }

  const getTagsList = async (search) => (await showTemplateTagsList({ page_size: 100, active: true, name__icontains: search })).data.results

  const handleCreateTag = async (value) => {
    try {
      const { data } = await createTemplateTag({ name: value })

      setValue('tags', [...getValues('tags'), data])
    } catch {
      notify.error('Não foi possível criar nova tag no momento.')
    }
  }

  const handleLowercaseInput = (event, fieldName) => {
    const lowercaseValue = event.target.value.toLowerCase()
    const valueWithUnderscores = lowercaseValue.replace(/\s+/g, '_')
    setValue(fieldName, valueWithUnderscores)
  }

  return (
    <Column gap='16px'>
      <Typography>Configure e crie o seu modelo de mensagem</Typography>
      {connections?.length > 1 && (
        <Row maxWidth='194px'>
          <Select
            label='Conexões'
            options={connections}
            keys={{ label: 'name', value: 'id' }}
            name='connection'
            control={control}
            error={errors?.connection}
            required
          />
        </Row>
      )}
      <Row>
        <CategorySelection options={categoriesOptions} control={control} {...register('category')} />
      </Row>
      <Row>
        <Checkbox label='Permitir que a meta altere a categoria deste modelo durante a aprovação' {...register('allow_template_category_change')} />
      </Row>
      <Column>
        <Card p='16px' gap='16px'>
          <Row gap='16px' flexDirection={['column', 'row']}>
            <Select
              maxWidth={['100%', '260px']}
              label='Selecione o tipo de template'
              options={templateTypesOptions}
              required
              control={control}
              error={errors?.template_type}
              {...register('template_type')}
            />
            <Select
              maxWidth={['100%', '260px']}
              label='Selecione o idioma do template'
              options={languageOptions}
              control={control}
              {...register('language')}
            />
          </Row>
          <Row gap='16px' flexDirection={['column', 'row']}>
            <Input
              maxWidth={['100%', '260px']}
              label='Dê um nome para seu template'
              placeholder='Insira o nome'
              error={errors?.name}
              errorMessage={errors?.name?.message}
              required
              onChange={(e) => handleLowercaseInput(e, 'name')}
              value={watch('name') || ''}
            />


            <SelectAsyncCreate
              maxWidth={['100%', '260px']}
              customStyles={{ control: (base) => ({ ...base, height: '100px', overflow: 'scroll' }) }}
              name='tags'
              isMulti
              label='Dê palavras chaves para o template'
              placeholder='Ex. Vendas, Produtos'
              loadOptions={getTagsList}
              keys={{ label: 'name', value: 'id' }}
              isCreatable
              onCreateOption={handleCreateTag}
              control={control}
              required
              error={errors?.tags}
            />
          </Row>
        </Card>
      </Column>
    </Column>
  )
}

export default Category
